<template>
  <v-app id="#app">
    <v-main>
      <TheAppBar
        @setDrawer="drawer = $event"
        :drawer="drawer"
        :currencyView="nameView"
      />
      <TheSideBar @setDrawerResponsiveness="drawer = $event" :drawer="drawer" />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from "@/components/shared/TheAppBar";
import TheSideBar from "@/components/shared/TheSideBar";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    TheAppBar,
    TheSideBar
  },

  data() {
    return {
      drawer: true,
      nameView: ""
    };
  },

  computed: {
    ...mapGetters({
      getUser: "getUser",
      getView: "sistema/getView"
    })
  },

  updated() {
    this.nameView = this.getView.name;
  },

  mounted() {
    this.nameView = localStorage.getItem("titlePage");
  }
};
</script>

<style>
@import "../../../assets/css/style.css";
</style>
