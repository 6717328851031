<template>
  <v-app-bar elevation="0" class="app-bar-style">
    <v-app-bar-nav-icon
      class="white--text"
      @click="activeDrawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-title class="white--text" color="black">
      {{ currencyView }}
    </v-toolbar-title>
    <v-btn
      icon
      class="ml-5"
      color="rgba(255,255,255, 0.3)"
      @click="hiddenValue()"
      v-if="seeBtn()"
    >
      <v-icon size="30" v-if="seeValue">mdi-eye-outline</v-icon>
      <v-icon size="30" v-else>mdi-eye-off-outline</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <v-menu transition="slide-y-transition" bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="white--text mr-1" text v-bind="attrs" v-on="on">
          {{ user }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <span class="white--text"> {{ rule }}: </span>
      </template>
      <v-list>
        <v-list-item to="/perfil">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-avatar size="36" color="white">
      <span color="#09316e" class="headline">{{ userNameInitial }}</span>
    </v-avatar>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TheAppBar",

  props: {
    drawer: {
      type: Boolean,
      default: true
    },

    currencyView: {
      type: String
    }
  },

  data() {
    return {
      drawerData: this.drawer,
      seeValue: true
    };
  },

  methods: {
    ...mapActions({
      fetchHiddenValue: "sistema/fetchHiddenValue"
    }),

    hiddenValue() {
      this.seeValue = !this.seeValue;
      this.fetchHiddenValue(this.seeValue);
    },

    seeBtn() {
      if (
        this.$router.history.current.path == "/comissao/dashboard" ||
        this.$router.history.current.path == "/campanhas/dashboard"
      ) {
        return true;
      }
      return false;
    },

    activeDrawer() {
      this.drawerData = !this.drawerData;
      this.$emit("setDrawer", this.drawerData);
    },

    init() {
      if (localStorage.getItem("hiddenValue") == "false") {
        this.seeValue = false;
        return this.fetchHiddenValue(this.seeValue);
      }
      this.seeValue = true;
      return this.fetchHiddenValue(this.seeValue);
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },

  mounted() {
    this.init();
  },

  computed: {
    userNameInitial() {
      if (this.getUser) {
        return this.getUser?.name?.substr(0, 1);
      }
      return "";
    },
    ...mapGetters({
      getUser: "getUser"
    }),

    user() {
      return this.getUser?.name;
    },
    rule() {
      return this.getUser?.rule?.nome;
    }
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    }
  }
};
</script>
<style scoped>
.app-bar-style {
  height: 140px !important;
  background: #141e30;
  background: linear-gradient(90deg, #0c2646 0, #204065 60%, #2a5788);
}
</style>
